/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

@import url('https://fonts.googleapis.com/css2?family=Gupter:wght@400;500;700&display=swap');

:root {


 --ff-primary: "Gupter", serif;


    /* Colors */
 --fc-main: #FCEFAD;
 --fc-secondary-main: #557085;


--fc-gradient-1: linear-gradient(to bottom, #7EC6FE 0%, #557085 100%);
--fc-gradient-2: linear-gradient(to bottom, #FFFFFF 0%, #7EC6FE 100%);


    /* Font Sizes  */

--fs-xlg: 50px;
--fs-lg: 48px;
--fs-md: 36px;
--fs-sm: 24px;
--fs-xsm: 16px;

    /* Font Weights */

--fw-700: 700;
--fw-500: 500;
--fw-400: 400;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


@media (1200px <= width ) {

.main-container-footer {


	background-color: #7EC6FE;


}

.main-ul {

	display: flex;
	justify-content: center;
	align-items: center;


}


.items {

	margin-right: 200px;
	margin-bottom: 50px;
}

#freelygave-name-footer {
	font-family: var(--ff-primary);
	font-size: var(--fs-md);
	color: black;
	width: 200px;

}

.address-footer {

	font-family: var(--ff-primary);
	font-size: var(--fs-sm);
	color: black;
    margin-top: 5px;
}
.secondary-ul {

	display: flex;
}

.pages1-footer, .pages2-footer {
	text-decoration: none;
	color: black;
	font-size: var(--fs-sm);
	text-decoration: underline;

}
.pages1-footer {
    margin-right: 50px;
}

.pages1 {
	margin-right: 50px;
}

.secondary-ul {

	margin-right: 100px;
}

.li-1 {
	margin-left: 200px;
}

.social-media-ul {

    display: flex;
}

.social-media-icons {
    margin-right: 20px;
    margin-top: 20px;
}

#instagram-icon, #facebook-icon, #x-icon {

    text-decoration: none;
    color: black;
}

.pages-second {

    margin-top: 15px;

}

#footer-image {
    width: 200px;
    
}
}


@media (1000px <= width < 1200px) {
    .main-container-footer {


        background-color: #7EC6FE;
    
    
    }
    
    .main-ul {
    
        display: flex;
        justify-content: center;
        align-items: center;
    
    
    }
    
    
    .items {
    
        margin-right:130px;
        margin-bottom: 50px;
    }
    
    #freelygave-name-footer {
        font-family: var(--ff-primary);
        font-size: 27px;
        color: black;
        width: 200px;
    
    }
    
    .address-footer {
    
        font-family: var(--ff-primary);
        font-size: var(--fs-sm);
        color: black;
        margin-top: 5px;
    }
    .secondary-ul {
    
        display: flex;
    }
    
    .pages1-footer, .pages2-footer {
        text-decoration: none;
        color: black;
        font-size: var(--fs-sm);
        text-decoration: underline;
    
    }

    .pages1-footer {
        margin-right: 50px;
    }
    
    .pages1 {
        margin-right: 50px;
    }
    
    .secondary-ul {
    
        margin-right: 100px;
    }
    
    .li-1 {
        margin-left: 200px;
    }
    
    .social-media-ul {
    
        display: flex;
    }
    
    .social-media-icons {
        margin-right: 20px;
        margin-top: 20px;
    }
    
    #instagram-icon, #facebook-icon, #x-icon {
    
        text-decoration: none;
        color: black;
    }
    
    .pages-second {
    
        margin-top: 15px;
    } 

    #footer-image {
        width: 50px;
        height: 50px;
        margin-right: 40px;
    }
}


@media (800px <= width < 1000px) {
    .main-container-footer {


        background-color: #7EC6FE;
    
    
    }
    
    .main-ul {
    
        display: flex;
        justify-content: center;
        align-items: center;
    
    
    }
    
    
    .items {
    
        margin-right:80px;
        margin-bottom: 50px;
    }
    
    #freelygave-name-footer {
        font-family: var(--ff-primary);
        font-size: 27px;
        color: black;
        width: 200px;
    
    }
    
    .address-footer {
    
        font-family: var(--ff-primary);
        font-size: var(--fs-sm);
        color: black;
        margin-top: 5px;
    }
    .secondary-ul {
    
        display: flex;
    }
    
    .pages1-footer, .pages2-footer {
        text-decoration: none;
        color: black;
        font-size: var(--fs-sm);
        text-decoration: underline;
    
    }

    .pages1-footer {
        margin-right: 50px;
    }
    
    .pages1 {
        margin-right: 50px;
    }
    
    .secondary-ul {
    
        margin-right: 100px;
    }
    
    .li-1 {
        margin-left: 100px;
    }
    
    .social-media-ul {
    
        display: flex;
    }
    
    .social-media-icons {
        margin-right: 20px;
        margin-top: 20px;
    }
    
    #instagram-icon, #facebook-icon, #x-icon {
    
        text-decoration: none;
        color: black;
    }
    
    .pages-second {
    
        margin-top: 15px;
    } 

    #footer-image {
        width: 100px;
        height: 100px;
        margin-right: 70px;
    }
}

@media (600px <= width < 800px) {
    .main-container-footer {


        background-color: #7EC6FE;
    
    
    }
    
    .main-ul {
    
        display: grid;
        justify-content: center;
        align-items: center;
    
    
    }
    
    
    .items {
    
        margin-right:80px;
        margin-bottom: 50px;
    }
    
    #freelygave-name-footer {
        font-family: var(--ff-primary);
        font-size: 32px;
        color: black;
        width: 200px;
    
    }
    
    .address-footer {
    
        font-family: var(--ff-primary);
        font-size: var(--fs-md);
        color: black;
        margin-top: 5px;
    }
    .secondary-ul {
    
        display: flex;

    }
    
    .pages1-footer, .pages2-footer {
        text-decoration: none;
        color: black;
        font-size: var(--fs-md);
        text-decoration: underline;
    
    }

    .pages1-footer {
        margin-right: 50px;
    }
    
    .pages1 {
        margin-right: 50px;
    }
    
    .secondary-ul {
    
        margin-right: 100px;
    }
    
    .li-1 {
        margin-left: 100px;
    }
    
    .social-media-ul {
    
        display: flex;
    }
    
    .social-media-icons {
        margin-right: 20px;
        margin-top: 20px;
    }
    
    #instagram-icon, #facebook-icon, #x-icon {
    
        text-decoration: none;
        color: black;
    }
    
    .pages-second {
    
        margin-top: 15px;
    } 

    #footer-image {
        width: 100px;
        height: 100px;
        margin-right: 70px;
    }
}

@media (570px <= width < 600px) {
    .main-container-footer {


        background-color: #7EC6FE;
    
    
    }
    
    .main-ul {
    
        display: grid;
        justify-content: center;
        align-items: center;
    
    
    }
    
    
    .items {
    
        margin-right:80px;
        margin-bottom: 50px;
    }
    
    #freelygave-name-footer {
        font-family: var(--ff-primary);
        font-size: 32px;
        color: black;
        width: 200px;
    
    }
    
    .address-footer {
    
        font-family: var(--ff-primary);
        font-size: var(--fs-md);
        color: black;
        margin-top: 5px;
    }
    .secondary-ul {
    
        display: flex;
            
        margin-right: 100px;
        position: relative;
        left: 100px;


    }
    
    .pages1-footer, .pages2-footer {
        text-decoration: none;
        color: black;
        font-size: var(--fs-md);
        text-decoration: underline;
    
    }

    .pages1-footer {
        margin-right: 50px
    }
    
    .pages1 {
        margin-right: 50px;
    }

    
    .li-1 {
        margin-left: 100px;
    }
    
    .social-media-ul {
    
        display: flex;
    }
    
    .social-media-icons {
        margin-right: 20px;
        margin-top: 20px;
    }
    
    #instagram-icon, #facebook-icon, #x-icon {
    
        text-decoration: none;
        color: black;
    }
    
    .pages-second {
    
        margin-top: 15px;
    } 

    #footer-image {
        width: 100px;
        height: 100px;
        margin-right: 70px;
    }
}




@media (width < 570px) {
    .main-container-footer {


        background-color: #7EC6FE;
    
    
    }
    
    .main-ul {
    
        display: grid;
        justify-content: center;
        align-items: center;
    
    
    }
    
    
    .items {
    
        margin-right:80px;
        margin-bottom: 50px;
    }
    
    #freelygave-name-footer {
        font-family: var(--ff-primary);
        font-size: 40px;
        color: black;
        width: 200px;
    
    }
    
    .address-footer {
    
        font-family: var(--ff-primary);
        font-size: var(--fs-md);
        color: black;
        margin-top: 5px;
    }
    .secondary-ul {
    
        display: flex;
            
        margin-right: 100px;
        position: relative;
        left: 50px;
        margin-right: 0px;


    }
    
    .pages1-footer, .pages2-footer {
        text-decoration: none;
        color: black;
        font-size: var(--fs-md);
        text-decoration: underline;
    
    }

    .pages1-footer {

        margin-right: 50px;
    }
    
    .pages1 {
        margin-right: 50px;
    }

    
    .li-1 {
        margin-left: 100px;
    }
    
    .social-media-ul {
    
        display: flex;
    }
    
    .social-media-icons {
        margin-right: 20px;
        margin-top: 20px;
    }
    
    #instagram-icon, #facebook-icon, #x-icon {
    
        text-decoration: none;
        color: black;
    }
    
    .pages-second {
    
        margin-top: 15px;
    } 

    #footer-image {
        width: 100px;
        height: 100px;
        margin-right: 70px;
    }
}