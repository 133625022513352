@import url('https://fonts.googleapis.com/css2?family=Gupter:wght@400;500;700&display=swap');

:root {
 --ff-primary: "Gupter", serif;

 /* Colors */
 --fc-main: #FCEFAD;
 --fc-secondary-main: #557085;

 --fc-gradient-1: linear-gradient(to bottom, #7EC6FE 0%, #557085 100%);
 --fc-gradient-2: linear-gradient(to bottom, #FFFFFF 0%, #7EC6FE 100%);

 /* Font Sizes  */
 --fs-xlg: 50px;
 --fs-lg: 48px;
 --fs-md: 32px;
 --fs-sm: 24px;
 --fs-xsm: 16px;

 /* Font Weights */
 --fw-700: 700;
 --fw-500: 500;
 --fw-400: 400;
}

/* Reset CSS */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* Utility Classes */

.navbarheader {
	background-image: url('../images/background-freelygave.png');
	background-size: cover;
	background-position: center;
	height: 812px;
	width: 100%;
}

.what-we-do {
	background-color: var(--fc-main);
}

#what-we-do-img {
	width: 150px;
	height: 150px;
	border-radius: 25px;
 }
/* Large Screens */
@media (min-width: 1200px) {
	.navbarheader {
		height: 812px;
	}
	.main-navbar {
		margin-left: 20px;
		margin-right: 20px;
	}
	#navbar-logo {
		font-size: var(--fs-xlg);
		font-weight: var(--fw-400);
		font-family: var(--ff-primary);
		color: white !important;
	}
	.nav-pages {
		font-size: var(--fs-sm);
		font-family: var(--ff-primary);
		color: white !important;
	}
	.nav-pages:hover {
		color: #b0b0af !important;
	}
	#basic-nav-dropdown {
		color: white !important;
	}
	#basic-nav-dropdown:hover {
		color: #b0b0af !important;
	}
	.main-navbar .navbar-collapse {
		justify-content: flex-end;
	}
	#navbar-logo {
		flex-grow: 1;
	}
}

/* Medium Screens */
@media (min-width: 1062px) and (max-width: 1199px) {
	.navbarheader {
		height: 812px;
	}
	.main-navbar {
		margin-left: 20px;
		margin-right: 20px;
	}
	#navbar-logo {
		font-size: var(--fs-xlg);
		font-weight: var(--fw-400);
		font-family: var(--ff-primary);
		color: white !important;
	}
	.nav-pages {
		font-size: var(--fs-sm);
		font-family: var (--ff-primary);
		color: white !important;
	}
	.nav-pages:hover {
		color: #b0b0af !important;
	}
	#basic-nav-dropdown {
		color: white !important;
	}
	#basic-nav-dropdown:hover {
		color: #b0b0af !important;
	}
	.main-navbar .navbar-collapse {
		justify-content: flex-end;
	}
	#navbar-logo {
		flex-grow: 1;
	}
}

/* Small-Medium Screens */
@media (min-width: 987px) and (max-width: 1061px) {
	.navbarheader {
		height: 812px;
	}
	.main-navbar {
		margin-left: 20px;
		margin-right: 20px;
	}
	#navbar-logo {
		font-size: var(--fs-xlg);
		font-weight: var(--fw-400);
		font-family: var(--ff-primary);
		color: white !important;
	}
	.nav-pages {
		font-size: var(--fs-sm);
		font-family: var(--ff-primary);
		color: white !important;
	}
	.nav-pages:hover {
		color: #b0b0af !important;
	}
	#basic-nav-dropdown {
		color: white !important;
	}
	#basic-nav-dropdown:hover {
		color: #b0b0af !important;
	}
	.main-navbar .navbar-collapse {
		justify-content: flex-end;
	}
	#navbar-logo {
		flex-grow: 1;
	}
}

/* Small Screens */
@media (min-width: 600px) and (max-width: 986px) {
	.navbarheader {
		height: 812px;
	}
	.main-navbar {
		margin-left: 20px;
		margin-right: 20px;
	}
	#navbar-logo {
		font-size: var(--fs-xlg);
		font-weight: var(--fw-400);
		font-family: var(--ff-primary);
		color: white !important;
	}
	.nav-pages {
		font-size: var(--fs-sm);
		font-family: var(--ff-primary);
		color: white !important;
	}
	.nav-pages:hover {
		color: #b0b0af !important;
	}
	#basic-nav-dropdown {
		color: white !important;
	}
	#basic-nav-dropdown:hover {
		color: #b0b0af !important;
	}
	.main-navbar .navbar-collapse {
		justify-content: flex-end;
	}
	#navbar-logo {
		flex-grow: 1;
	}
}

/* Extra Small Screens */
@media (max-width: 599px) {
	.navbarheader {
		height: 600px;
		background-size: cover;
		background-position: center;
		width: 100%;
	}
	.main-navbar {
		margin-left: 20px;
		margin-right: 20px;
	}
	#navbar-logo {
		font-size: var(--fs-xlg);
		font-weight: var(--fw-400);
		font-family: var(--ff-primary);
		color: white !important;
	}
	.nav-pages {
		font-size: var(--fs-sm);
		font-family: var(--ff-primary);
		color: white !important;
	}
	.nav-pages:hover {
		color: #b0b0af !important;
	}
	#basic-nav-dropdown {
		color: white !important;
	}
	#basic-nav-dropdown:hover {
		color: #b0b0af !important;
	}
	.main-navbar .navbar-collapse {
		justify-content: flex-end;
	}
	#navbar-logo {
		flex-grow: 1;
	}
}

/* What We Do Section */
.what-we-do {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 50px 20px;
}

.what-we-do h2 {
	font-family: var(--ff-primary);
	font-size: var(--fs-lg);
	margin-bottom: 20px;
}

.what-we-do p {
	font-family: var(--ff-primary);
	font-size: var(--fs-sm);
	max-width: 800px;
	margin-bottom: 20px;
}

.what-we-do img {
	max-width: 100%;
	height: auto;
}


