/* Add this to your CSS file */

.body-container-contact {
    background: var(--fc-gradient-2);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 100px 20px 20px;
}

.main-container-contact {
    width: 100%;
    max-width: 800px; /* Increased max-width */
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.label-text, .message-area, #submit-button {
    margin-bottom: 20px;
    width: 100%;
}

.input-tags, .message-area {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    height: auto; /* Remove fixed height */
}

.message-area {
    min-height: 300px; /* Minimum height for better usability */
    resize: vertical; /* Allow vertical resizing */
}

#submit-button {
    cursor: pointer;
    padding: 10px 20px;
    width: 100%;
}

.label-text {
    font-size: var(--fs-sm);
    font-family: var(--ff-primary);
}

.label-text input, .label-text textarea {
    border-radius: 25px;
    border: none;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

#submit-button {
    font-family: var(--ff-primary);
    font-size: var(--fs-sm);
    background: transparent;
    border: 2px solid var(--fc-secondary-main);
    border-radius: 25px;
    transition: background-color 0.3s, color 0.3s;
}

#submit-button:hover {
    background-color: var(--fc-secondary-main);
    color: white;
}

.contact-title {
    font-family: var(--ff-primary);
    font-size: var(--fs-md);
    margin-bottom: 20px;
    text-align: center;
}

@media (min-width: 651px) {
    .body-container-contact {
        height: 1000px;
        padding-top: 150px;
    }

    .main-container-contact {
        max-width: 800px;
    }

    .label-text input, .label-text textarea {
        height: auto;
    }

    #submit-button {
        width: 100%;
    }

}

@media (max-width: 650px) {
    .main-container-contact {
        max-width: 100%;
        padding: 10px;
    }

    .label-text input, .label-text textarea {
        height: auto;
    }

    #submit-button {
        width: 100%;
    }
}
