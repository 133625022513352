/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

@import url('https://fonts.googleapis.com/css2?family=Gupter:wght@400;500;700&display=swap');

:root {


 --ff-primary: "Gupter", serif;


    /* Colors */
 --fc-main: #FCEFAD;
 --fc-secondary-main: #557085;


--fc-gradient-1: linear-gradient(to bottom, #7EC6FE 0%, #557085 100%);
--fc-gradient-2: linear-gradient(to bottom, #FFFFFF 0%, #7EC6FE 100%);


    /* Font Sizes  */

--fs-xlg: 50px;
--fs-lg: 48px;
--fs-md: 32px;
--fs-sm: 24px;
--fs-xsm: 16px;

    /* Font Weights */

--fw-700: 700;
--fw-500: 500;
--fw-400: 400;
}


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


.mainheader {

    background-color: var(--fc-main);
    height: 80px;
}

.main-navbar {


}

.nav-pages-header {
    font-size: var(--fs-sm);
	font-family: var(--ff-primary);
	color: black!important;
}

#basic-nav-dropdown-header {
    font-size: var(--fs-sm);
	font-family: var(--ff-primary);
	color: black!important;

}

#navbar-logo-header {

    font-size: var(--fs-xlg);
    font-weight: var(--fw-400);
    font-family: var(--ff-primary);
	color: black !important;
}




