/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

@import url('https://fonts.googleapis.com/css2?family=Gupter:wght@400;500;700&display=swap');

:root {
 --ff-primary: "Gupter", serif;

 /* Colors */
 --fc-main: #FCEFAD;
 --fc-secondary-main: #557085;

 --fc-gradient-1: linear-gradient(to bottom, #7EC6FE 0%, #557085 100%);
 --fc-gradient-2: linear-gradient(to bottom, #FFFFFF 0%, #7EC6FE 100%);

 /* Font Sizes  */
 --fs-xlg: 50px;
 --fs-lg: 48px;
 --fs-md: 32px;
 --fs-sm: 24px;
 --fs-xsm: 16px;

 /* Font Weights */
 --fw-700: 700;
 --fw-500: 500;
 --fw-400: 400;
}

/* Reset CSS */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* Header Styles */
header {
   height: 812px;
   background-size: cover;
}



/* Large Screens (>= 1550px) */
@media (min-width: 1550px) {
   header {
      height: 812px;
      background-size: cover;
   }
   .yellow-container {
      background-color: var(--fc-main);
      height: 384px;
      display: grid;
      align-items: center;
      justify-content: center;
   }
   .blue-container {
      background: var(--fc-gradient-1);
      height: 2189px;
      display: block;
   }
   .li-about {
      font-family: var(--ff-primary);
      font-size: var(--fs-lg);
      margin-right: 100px;
      margin-left: 20px;
   }
   #what-we-do {
      font-weight: 700;
   }
   .ul-about {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 319px;
      width: 1361px;
      margin: 0 auto;
   }
   #blue-text-1, #blue-text-2, #blue-text-3 {
      font-family: var(--ff-primary);
      height: 690px;
      width: 690px;
      font-weight: 400;
      font-size: 56px;

   }
   .blue-1-ul, .blue-2-ul, .blue-3-ul {
      display: flex;
      margin: 50px;
   }
   .image-tag {
      width: 700px;
      height: 700px;
   }
   #blue-text-1, #blue-text-3, #blue-text-2 {
      text-align: right;
   }
   .blue-1, .blue-2, .blue-3 {
      display: flex;
      justify-content: center;
      align-items: center;
   }
}

/* Medium-Large Screens (1400px - 1549px) */
@media (min-width: 1400px) and (max-width: 1549px) {
   header {
      height: 812px;
      background-size: cover;
   }
   .yellow-container {
      background-color: var(--fc-main);
      height: 384px;
      display: grid;
      align-items: center;
      justify-content: center;
   }
   .blue-container {
      background: var(--fc-gradient-1);
      height: 2189px;
      display: block;
   }
   .li-about {
      font-family: var(--ff-primary);
      font-size: var(--fs-lg);
      margin-right: 100px;
   }
   #what-we-do {
      font-weight: 700;
   }
   .ul-about {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 319px;
      width: 1361px;
      margin: 0 auto;
   }
   #blue-text-1, #blue-text-2, #blue-text-3 {
      font-family: var(--ff-primary);
      height: 490px;
      width: 490px;
      font-weight: 400;
      font-size: var(--fs-lg);
   }
   .blue-1-ul, .blue-2-ul, .blue-3-ul {
      display: flex;
      margin: 50px;
   }
   .image-tag {
      width: 500px;
      height: 500px;
   }
   #blue-text-1, #blue-text-3, #blue-text-2 {
      text-align: right;
   }
   .blue-1, .blue-2, .blue-3 {
      display: flex;
      justify-content: center;
      align-items: center;
   }
}

/* Medium Screens (1300px - 1399px) */
@media (min-width: 1300px) and (max-width: 1399px) {
   header {
      height: 812px;
      background-size: cover;
   }
   .yellow-container {
      background-color: var(--fc-main);
      height: 384px;
      display: grid;
      align-items: center;
      justify-content: center;
   }
   .blue-container {
      background: var(--fc-gradient-1);
      height: 2189px;
      display: block;
   }
   .li-about {
      font-family: var(--ff-primary);
      font-size: 45px;
      margin-right: 50px;
   }
   #what-we-do {
      font-weight: 700;
   }
   .ul-about {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 319px;
      width: 95%;
      margin: 0 auto;
   }
   #blue-text-1, #blue-text-2, #blue-text-3 {
      font-family: var(--ff-primary);
      height: 500px;
      width: 500px;
      font-weight: 700;
      font-size: var(--fs-lg);
   }
   .blue-1-ul, .blue-2-ul, .blue-3-ul {
      display: flex;
      margin: 50px;
   }
   .image-tag {
      width: 500px;
      height: 500px;
   }
   #blue-text-1, #blue-text-3 {
      text-align: right;
   }
   .blue-1, .blue-2, .blue-3 {
      display: flex;
      justify-content: center;
      align-items: center;
   }
}

/* Small-Medium Screens (1200px - 1299px) */
@media (min-width: 1200px) and (max-width: 1299px) {
   header {
      height: 812px;
      background-size: cover;
   }
   .yellow-container {
      background-color: var(--fc-main);
      height: 384px;
      display: grid;
      align-items: center;
      justify-content: center;
   }
   .blue-container {
      background: var(--fc-gradient-1);
      height: 2189px;
      display: block;
   }
   .li-about {
      font-family: var(--ff-primary);
      font-size: 45px;
      margin-right: 50px;
   }
   #what-we-do {
      font-weight: 700;
   }
   .ul-about {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 319px;
      width: 1361px;
   }
   #blue-text-1, #blue-text-2, #blue-text-3 {
      font-family: var(--ff-primary);
      height: 500px;
      width: 500px;
      font-weight: 700;
      font-size: var(--fs-lg);
   }
   .blue-1-ul, .blue-2-ul, .blue-3-ul {
      display: flex;
      margin: 50px;
   }
   .image-tag {
      width: 500px;
      height: 500px;
   }
   #blue-text-1, #blue-text-3 {
      text-align: right;
   }
   .blue-1, .blue-2, .blue-3 {
      display: flex;
      justify-content: center;
      align-items: center;
   }
}

/* Small Screens (1000px - 1199px) */
@media (min-width: 1000px) and (max-width: 1199px) {
   header {
      height: 812px;
      background-size: cover;
   }
   .yellow-container {
      background-color: var(--fc-main);
      height: 384px;
      display: grid;
      align-items: center;
      justify-content: center;
   }
   .blue-container {
      background: var(--fc-gradient-1);
      height: 2189px;
      display: block;
   }
   .li-about {
      font-family: var(--ff-primary);
      font-size: 38px;
      margin-right: 50px;
   }
   #what-we-do {
      font-weight: 700;
   }
   .ul-about {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 319px;
      width: 1361px;
      margin-left: 20px;
   }
   #blue-text-1, #blue-text-2, #blue-text-3 {
      font-family: var(--ff-primary);
      height: 500px;
      width: 500px;
      font-weight: 700;
      font-size: var(--fs-lg);
   }
   .blue-1-ul, .blue-2-ul, .blue-3-ul {
      display: flex;
      margin: 50px;
   }
   .image-tag {
      width: 500px;
      height: 500px;
   }
   #blue-text-1, #blue-text-3 {
      text-align: right;
   }
   .blue-1, .blue-2, .blue-3 {
      display: flex;
      justify-content: center;
      align-items: center;
   }
}

/* Extra Small Screens (800px - 999px) */
@media (min-width: 800px) and (max-width: 999px) {
   header {
      height: 812px;
      background-size: cover;
   }
   .yellow-container {
      background-color: var(--fc-main);
      height: 384px;
      display: grid;
      align-items: center;
      justify-content: center;
   }
   .blue-container {
      background: var(--fc-gradient-1);
      height: 2189px;
      display: block;
   }
   .li-about {
      font-family: var(--ff-primary);
      font-size: 40px;
      margin-right: 50px;
      width: 200px;
   }
   #what-we-do {
      font-weight: 700;
   }
   .ul-about {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 319px;
      width: 1361px;
      margin-left: 20px;
   }
   #blue-text-1, #blue-text-2, #blue-text-3 {
      font-family: var(--ff-primary);
      height: 390px;
      width: 390px;
      font-weight: 400;
      font-size: 40px;
   }
   .blue-1-ul, .blue-2-ul, .blue-3-ul {
      display: flex;
      margin: 50px;
   }
   .image-tag {
      width: 400px;
      height: 400px;
   }
   #blue-text-1, #blue-text-3 {
      text-align: right;
   }
   .blue-1, .blue-2, .blue-3 {
      display: flex;
      justify-content: center;
      align-items: center;
   }
}

/* Extra Small Screens (600px - 799px) */
@media (min-width: 600px) and (max-width: 799px) {
   header {
      height: 812px;
      background-size: cover;
   }
   .yellow-container {
      background-color: var(--fc-main);
      height: 384px;
      display: grid;
      align-items: center;
      justify-content: center;
   }
   .blue-container {
      background: var(--fc-gradient-1);
      height: 2189px;
      display: block;
   }
   .li-about {
      font-family: var(--ff-primary);
      font-size: 40px;
      margin-right: 50px;
      width: 200px;
   }
   #what-we-do {
      font-weight: 700;
   }
   .ul-about {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 319px;
      width: 1361px;
      margin-left: 20px;
   }
   #blue-text-1, #blue-text-2, #blue-text-3 {
      font-family: var(--ff-primary);
      height: 290px;
      width: 290px;
      font-weight: 400;
      font-size: 35px;
   }
   .blue-1-ul, .blue-2-ul, .blue-3-ul {
      display: flex;
      margin: 50px;
   }
   .image-tag {
      width: 300px;
      height: 300px;
   }
   #blue-text-1, #blue-text-3 {
      text-align: right;
   }
   .blue-1, .blue-2, .blue-3 {
      display: flex;
      justify-content: center;
      align-items: center;
   }
}

/* Extra Small Screens (570px - 599px) */
@media (min-width: 570px) and (max-width: 599px) {
   .yellow-container {
      background-color: var(--fc-main);
      height: 384px;
      display: grid;
      align-items: center;
      justify-content: center;
   }
   .blue-container {
      background: var(--fc-gradient-1);
      height: 2189px;
      display: block;
   }
   .li-about {
      font-family: var(--ff-primary);
      font-size: 40px;
      margin-right: 50px;
      width: 200px;
   }
   #what-we-do {
      font-weight: 700;
   }
   .ul-about {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 319px;
   }
   #blue-text-1, #blue-text-2, #blue-text-3 {
      font-family: var(--ff-primary);
      height: 190px;
      width: 190px;
      font-weight: 400;
      font-size: 30px;
   }
   .blue-1-ul, .blue-2-ul, .blue-3-ul {
      display: flex;
      margin: 40px;
   }
   .image-tag {
      width: 300px;
      height: 300px;
   }
   #blue-text-1, #blue-text-3 {
      text-align: right;
   }
   .blue-1, .blue-2, .blue-3 {
      display: flex;
      justify-content: center;
      align-items: center;
   }
}

/* Very Small Screens (<= 569px) */
@media (max-width: 569px) {
   .yellow-container {
      background-color: var(--fc-main);
      height: 384px;
      display: grid;
      align-items: center;
      justify-content: center;
   }
   .blue-container {
      background: var(--fc-gradient-1);
      height: 2189px;
      display: block;
   }
   .li-about {
      font-family: var(--ff-primary);
      font-size: 35px;
      margin-right: 50px;
      width: 200px;
   }
   #what-we-do {
      font-weight: 700;
      margin-top: 50px;
   }
   #what-we-do-message {
      margin-bottom: 30px;
   }
   .ul-about {
      display: grid;
      justify-content: center;
      text-align: center;
      align-items: center;
      height: 319px;
   }
   #blue-text-1, #blue-text-2, #blue-text-3 {
      font-family: var(--ff-primary);
      height: 300px;
      width: 300px;
      font-weight: 400;
      font-size: 30px;
   }
   .blue-1-ul, .blue-2-ul, .blue-3-ul {
      display: grid;
      margin: 40px;
   }
   .image-tag {
      width: 300px;
      height: 300px;
   }
   .blue-1, .blue-2, .blue-3 {
      display: flex;
      justify-content: center;
      align-items: center;
   }
}
