/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

/* @media (width < 576px ) {

}

@media (576px <= width <768px ) {


}

@media (768px <= width < 992px) {


}

@media (992px <= width < 1200px) {


}

@media (1200 <= width < 1400px){


}

@media (width >= 1400px) {


} */
@import url('https://fonts.googleapis.com/css2?family=Gupter:wght@400;500;700&display=swap');

:root {


 --ff-primary: "Gupter", serif;


    /* Colors */
 --fc-main: #FCEFAD;
 --fc-secondary-main: #557085;


--fc-gradient-1: linear-gradient(to bottom, #7EC6FE 0%, #557085 100%);
--fc-gradient-2: linear-gradient(to bottom, #FFFFFF 0%, #7EC6FE 100%);


    /* Font Sizes  */

--fs-xlg: 50px;
--fs-lg: 48px;
--fs-md: 36px;
--fs-sm: 24px;
--fs-xsm: 16px;

    /* Font Weights */

--fw-700: 700;
--fw-500: 500;
--fw-400: 400;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


@media (width >= 1200px) {

.main-container {


	background-color: #557085;


}

.main-ul {

	display: flex;
	justify-content: center;
	align-items: center;


}


.items {

	margin-right: 200px;
	margin-bottom: 50px;
}

#freelygave-name {
	font-family: var(--ff-primary);
	font-size: var(--fs-md);
	color: white;
	width: 200px;

}

.address {

	font-family: var(--ff-primary);
	font-size: var(--fs-sm);
	color: white;
	margin-top: 5px;
}

.secondary-ul {

	display: flex;
}

.pages1, .pages2 {
	text-decoration: none;
	color: white;
	font-size: var(--fs-sm);
	text-decoration: underline;
	
}

.pages1 {
	margin-right: 50px;
}

.secondary-ul {

	margin-right: 100px;
}

.li-1 {
	margin-left: 200px;
}

#footer-image {
    width: 200px;
    
}


}

@media (1000px <= width < 1200px) {
	.main-container {


		background-color: #557085;
	
	
	}
	
	.main-ul {
	
		display: flex;
		justify-content: center;
		align-items: center;
	
	
	}
	
	
	.items {
	
		margin-right: 140px;
		margin-bottom: 50px;
	}
	
	#freelygave-name {
		font-family: var(--ff-primary);
		font-size: 27px;
		color: white;
		width: 200px;
	
	}
	
	.address {
	
		font-family: var(--ff-primary);
		font-size: var(--fs-sm);
		color: white;
		margin-top: 5px;
	}
	
	.secondary-ul {
	
		display: flex;
	}
	
	.pages1, .pages2 {
		text-decoration: none;
		color: white;
		font-size: var(--fs-sm);
		text-decoration: underline;
		
	}
	
	.pages1 {
		margin-right: 50px;
	}
	
	.secondary-ul {
	
		margin-right: 100px;
	}
	
	.li-1 {
		margin-left: 200px;
	}
    .social-media-ul {
    
        display: flex;
    }
    
    .social-media-icons {
        margin-right: 20px;
        margin-top: 20px;
    }
    
    #instagram-icon, #facebook-icon, #x-icon {
    
        text-decoration: none;
        color: black;
    }
	#footer-image {
        width: 100px;
        height: 100px;
        margin-right: 40px;
    }
}

@media (800px <= width < 1000px) {
	.main-container {


		background-color: #557085;
	
	
	}
	
	.main-ul {
	
		display: flex;
		justify-content: center;
		align-items: center;

	
	
	}
	
	
	.items {
	
		margin-right: 80px;
		margin-bottom: 50px;
	}

	.link-items {
		margin-right: 0px;
		margin-bottom: 50px;
	}
	
	#freelygave-name {
		font-family: var(--ff-primary);
		font-size: 27px;
		color: white;
		width: 200px;
	
	}
	
	.address {
	
		font-family: var(--ff-primary);
		font-size: var(--fs-sm);
		color: white;
		margin-top: 5px;
	}
	
	.secondary-ul {
	
		display: flex;

	}
	
	.pages1, .pages2 {
		text-decoration: none;
		color: white;
		font-size: var(--fs-sm);
		text-decoration: underline;
		
	}
	
	.pages1 {
		margin-right: 50px;
	}
	
	.secondary-ul {
	
		margin-right: 100px;
	}
	
	.li-1 {
		margin-left: 100px;
	}
    .social-media-ul {
    
        display: flex;
    }
    
    .social-media-icons {
        margin-right: 20px;
        margin-top: 20px;
    }
    
    #instagram-icon, #facebook-icon, #x-icon {
    
        text-decoration: none;
        color: black;
    }
	#footer-image {
        width: 100px;
        height: 100px;
        margin-right: 70px;
    }

	.items #footer-image {
		width: 100px;
		margin: 0px;
		margin-left: 0px;

	}
}


@media (600px <= width < 800px) {

	.main-container {


		background-color: #557085;
	
	
	}
	
	.main-ul {
	
		display: grid;
		justify-content: center;
		align-items: center;

	
	
	}
	
	
	.items {
	
		margin-right: 80px;
		margin-bottom: 50px;
	}

	.link-items {
		margin-right: 0px;
		margin-bottom: 50px;
	}
	
	#freelygave-name {
		font-family: var(--ff-primary);
		font-size: 32px;
		color: white;
		width: 200px;
	
	}
	
	.address {
	
		font-family: var(--ff-primary);
		font-size: var(--fs-md);
		color: white;
		margin-top: 5px;
	}
	
	.secondary-ul {
	
		display: grid;
		justify-content: center;
		margin-left: 110px;

	}
	
	.pages1, .pages2 {
		text-decoration: none;
		color: white;
		font-size: var(--fs-md);
		text-decoration: underline;
		
	}
	
	.pages1 {
		margin-right: 50px;
	}
	
	.secondary-ul {
	
		margin-right: 100px;
	}
	
	.li-1 {
		margin-left: 100px;
	}
    .social-media-ul {
    
        display: flex;
    }
    
    .social-media-icons {
        margin-right: 20px;
        margin-top: 20px;
    }
    
    #instagram-icon, #facebook-icon, #x-icon {
    
        text-decoration: none;
        color: black;
    }
	#footer-image {
        width: 100px;
        height: 100px;
        margin-right: 70px;
		display:none;
    }

	.items #footer-image {
		width: 100px;
		margin: 0px;
		margin-left: 0px;
		display:none;

	}
}

@media (570px <= width < 600px) {

	.main-container {


		background-color: #557085;
	
	
	}
	
	.main-ul {
	
		display: grid;
		justify-content: center;
		align-items: center;
		margin: 0px;

	
	
	}
	
	
	.items {
	
		margin-right: 70px;
		margin-bottom: 50px;
	}

	.link-items {
		margin-right: 0px;
		margin-bottom: 50px;
		margin: 0;
	}
	
	#freelygave-name {
		font-family: var(--ff-primary);
		font-size: 32px;
		color: white;
		width: 200px;
	
	}
	
	.address {
	
		font-family: var(--ff-primary);
		font-size: var(--fs-md);
		color: white;
		margin-top: 5px;
	}
	
	.secondary-ul {
	
		display: grid;
		justify-content: center;


	}
	
	.pages1, .pages2 {
		text-decoration: none;
		color: white;
		font-size: var(--fs-md);
		text-decoration: underline;
		
	}
	
	.pages1 {
		margin-right: 50px;
	}
	
	.secondary-ul {
	
		margin-right: 100px;
	}
	
	.li-1 {
		margin-left: 100px;
	}
    .social-media-ul {
    
        display: flex;
    }
    
    .social-media-icons {
        margin-right: 20px;
        margin-top: 20px;
    }
    
    #instagram-icon, #facebook-icon, #x-icon {
    
        text-decoration: none;
        color: black;
    }
	#footer-image {
        width: 100px;
        height: 100px;
        margin-right: 70px;
		display:none;
    }

	.items #footer-image {
		width: 100px;
		margin: 0px;
		margin-left: 0px;
		display:none;

	}
}

@media (width < 570px) {

	.main-container {


		background-color: #557085;
	
	
	}
	
	.main-ul {
	
		display: grid;
		justify-content: center;
		align-items: center;
		margin: 0px;

	
	
	}
	
	
	.items {
	
		margin-right: 50px;
		margin-bottom: 30px;
	}

	.link-items {
		margin-right: 0px;
		margin-bottom: 50px;
		margin: 0;
	}
	
	#freelygave-name {
		font-family: var(--ff-primary);
		font-size: 40px;
		color: white;
		width: 200px;
	
	}
	
	.address {
	
		font-family: var(--ff-primary);
		font-size: var(--fs-md);
		color: white;
		margin-top: 5px;
	}
	
	.secondary-ul {
	
		display: grid;
		justify-content: center;


	}
	
	.pages1, .pages2 {
		text-decoration: none;
		color: white;
		font-size: var(--fs-md);
		text-decoration: underline;
		
	}
	
	.pages1 {
		margin-right: 50px;
	}
	
	.secondary-ul {
	
		margin-right: 100px;
	}
	
	.li-1 {
		margin-left: 100px;
	}
    .social-media-ul {
    
        display: flex;
    }
    
    .social-media-icons {
        margin-right: 20px;
        margin-top: 20px;
    }
    
    #instagram-icon, #facebook-icon, #x-icon {
    
        text-decoration: none;
        color: black;
    }
	#footer-image {
        width: 100px;
        height: 100px;
        margin-right: 70px;
		display:none;
    }

	.items #footer-image {
		width: 100px;
		margin: 0px;
		margin-left: 0px;
		display:none;

	}
}